import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Col, Row, Badge } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from "../../components/sidebar"

export default () => {
  return (
    <Layout>
      <SEO title="Strangers May Kiss" />
      <Sidebar>
        <StaticQuery
          query={graphql`
            query StrangersQuery {
              allFile(
                filter: { relativeDirectory: { eq: "strangers" } }
                sort: { fields: name }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      fluid(quality: 90) {
                        aspectRatio
                        base64
                        sizes
                        srcSet
                        src
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <Row>
              <Col
                className="show-less justify-content-end"
                xs={{ span: 12, order: "first" }}
              >
                <Link to="/work">
                  <Badge pill variant="dark">
                    Show less
                  </Badge>
                </Link>
              </Col>
              <Col xs={12} className="mb-2">
                <div className="video-container">
                  <iframe
                    title="youtube"
                    src="https://www.youtube.com/embed/fG53pACKrWM"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
              {data.allFile.edges.map(({ node }) => (
                <Col xs={12} className="mb-2">
                  <Img fluid={node.childImageSharp.fluid} />
                </Col>
              ))}
            </Row>
          )}
        />
      </Sidebar>
    </Layout>
  )
}
